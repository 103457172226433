.is_flex {
  display: flex;
}
.horizontal_fx {
  flex-direction: row;
}
.vertical_fx {
  flex-direction: column;
}

.ai_center {
  align-items: center;
}

.jc_center {
  justify-content: center;
}
