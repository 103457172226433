@font-face {
  font-family: TTCommons;
  font-weight: bold;
  src: url("../public/fonts/TTCommonsDemiBold.otf") format("opentype");
}

.app_header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.app_body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.landing_title {
  font-family: "League Gothic", sans-serif;
  color: #f3ff0d;
  font-size: 95px;
}

.landing_subtitle {
  font-family: "TTCommons", sans-serif;
  color: white;
  font-size: 24px;
}

.title_container {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.header_title {
  padding: 20px;
}

.socials_section {
  padding: 10px 20px;
  background-color: #f2ff3d;
  flex-direction: column;
  border-bottom-left-radius: 25px;
  align-self: self-start;
}

.social_icon {
  padding: 10px 0px;
  width: 30px;
  height: 30px;
}

.footer_box {
  text-transform: uppercase;
  background-color: #0d0d0d;
  color: #f3ff0d;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 45px;
  align-items: center;
}

.footer_option {
  flex: 1;
  text-align: center;
  a {
    font-family: "TTCommons", sans-serif;
    color: #f3ff0d !important;
    text-decoration: none;
    font-size: 16px;
  }
}

.footer_center_gg {
  font-family: "League Gothic", sans-serif;
  color: #f3ff0d;
  font-size: 60px;
}

/* Small Iphone SE Styles */

@media (max-width: 375px) {
  .landing_title {
    font-size: 45px;
  }

  .landing_subtitle {
    font-size: 22px;
  }
}

/* Medium Iphone 12 Pro Styles */

@media (max-width: 414px) {
  .landing_title {
    font-size: 65px;
  }

  .landing_subtitle {
    font-size: 24px;
  }
}

/* Surface Duo Device Styles */

@media (max-width: 540px) {
  .landing_title {
    font-size: 65px;
  }

  .landing_subtitle {
    font-size: 24px;
  }
}
